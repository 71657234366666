
import Vue from "vue"
import Login from "@/components/Login.vue"

export default Vue.extend({
  components: {
    Login
  },
  data: () => ({
    show: true
  })
})
