
import { EventBus } from "@/plugins/eventBus"
import axios, { AxiosError, AxiosResponse } from "axios"
import Vue from "vue"
import { state, mutations } from "@/plugins/state"
import Locale from "@/components/Locale.vue"

export default Vue.extend({
  name: "Login",
  data: () => ({
    regex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    textError: "",
    email: "",
    password: "",
    token: state.token,
    show: false
    }),
  components: {
    Locale
  },
  methods: {
    login() {
      axios
        .post("/user/login", {
          email: this.email,
          password: this.password,
        })
        .then((response: AxiosResponse) => {
          this.token = response.data["token"]
          mutations.login(this.token)
          this.$router.push("/home")
          mutations.resetSpaces()
        })
        .catch((error: AxiosError) => {
          EventBus.$emit('snackbar',{axiosError: error})
        })
    }
  }
})
